

import axios from "axios";
import user from "@/utils/user";

const baseURL = process.env.NODE_ENV==='development'?'http://192.168.200.41:8001':'https://registapi.caichong.net';
const uploadFileAPI = async (data, callback) => {
    const token = user.token || localStorage.getItem('_TOKEN');
    try {
        const formData = new FormData();
        formData.append("fileForm", data);

        await axios.post(`${baseURL}/user/uploadImg`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'token': token
            },
            onUploadProgress: (ProgressEvent) => {
                callback({
                    status: 1,
                    ProgressEvent
                })
                // const { loaded, total } = ProgressEvent;
                // const progress = Math.round((loaded / total) * 100);
                // console.log(`Upload Progress: ${progress}%`);
            }
        }).then((rs)=>{
            callback({
                status: 2,
                data:rs.data
            })
        });

        // console.log('Upload complete');
        // console.log('Response:', reponse.data);
    } catch (error) {
        callback({
            status: 0,
            error: error
        })
    }
};
// const uploadFileAPI = (data/*, fileName, formName='fileForm'*/) => {
//     return new Promise( (resolve, reject) => {
//         try {
//             const formData = new FormData();
//             formData.append('flieForm', data);

//             const reponse = axios.post('http://localhost/src/upload.php', formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 },
//                 onUploadProgress: (ProgressEvent) => {
//                     resolve(ProgressEvent);
//                     const { loaded, total } = ProgressEvent;
//                     const progress = Math.round((loaded / total) * 100);
//                     // console.log(`Upload Progress: ${progress}%`);
//                 }
//             });
//             resolve('Response:')
//             // console.log('Upload complete');
//             // console.log('Response:', reponse.data);
//         } catch (error) {
//             console.error('Upload Failed', error);
//             reject(error);
//         }
//     });
// }

export default uploadFileAPI;



