import { request } from '@/utils';

/*
    拉取音乐列表
    startid	int; 起始id ; 默认0
    limit	int
*/
function getRecommedListeAPI(params) {
    return request({
        url: '/song/recommedList',
        method: 'GET',
        params: params
    });
}
/*
拉取音乐列表
songid: string
*/
function getSongInfoAPI(params) {
    return request({
        url: '/song/info',
        method: 'GET',
        params: params
    });
}

/*
下载歌曲
songid: string
*/
function downloadAPI(params) {
    return request({
        url: '/song/download',
        method: 'GET',
        params: params
    });
}

/*
我的音乐
*/
function getMySongAPI(params) {
    return request({
        url: '/song/mysong',
        method: 'GET',
        params: params
    });
}


/**
 * 
生成预支付订单
songid	string	描述词
*/
function createPreOrderAPI(formData) {
    return request({
        url: '/trade/makeNewOrder',
        method: 'POST',
        params: formData
    })
}

/**
 * 
生成预支付订单
preorderid  int
t  int 时间戳
sign 签名
*/
function createPayInfoAPI(formData) {
    return request({
        url: '/trade/payInfo',
        method: 'POST',
        params: formData
    })
}

/**
 * 
获取支付结果
preorderid: int
*/
function getPayResultAPI(formData) {
    return request({
        url: '/trade/payResult',
        method: 'POST',
        params: formData
    })
}

// 收藏音乐列表  /song/collect/list?pagesize=1&currpage=1
function getSongCollectList(formData) {
    return request({
        url: '/song/collect/list',
        method: 'GET',
        params: formData
    })
}

// 收藏音乐
function getSongCollect(data) {
    return request({
        url: '/song/collect',
        method: 'POST',
        data
    })
}

// 排行榜 song/order?type=1 1:日排 2:周排 3：月排 4：总排
function getSongOrder(formData) {
    return request({
        url: '/song/order',
        method: 'GET',
        params: formData
    })
}

export {
    getRecommedListeAPI,
    getSongInfoAPI,
    downloadAPI,
    getMySongAPI,
    createPreOrderAPI,
    createPayInfoAPI,
    getPayResultAPI,
    getSongCollectList,
    getSongCollect,
    getSongOrder
};