
import { useState, memo, useCallback } from "react";
import { createPortal } from "react-dom";
import { getLimitSize } from "@/utils";
import b64ToUint8Array from "@/utils/b64ToUnit8";
import ReactCrop from 'react-image-crop';
import classNames from "classnames";
import ButtonGradientPr from "../buttonGradientPr";
import "react-image-crop/dist/ReactCrop.css";

import "./index.scss"
import useModalFade from "@/hooks/useModalFade";
import uploadFileAPI from "@/apis/upload";
import { toast } from "../modals";
// import { toast } from "../Toast";

const CropImage = memo(({ src, imgDislaySize, setCompletedCrop, selectSize }) => {
    const [crop, setCrop] = useState({
        unit: 'px',
        x: Math.min(100, (selectSize.width - selectSize.width) / 2),
        y: (selectSize.height - selectSize.height) / 2,
        ...selectSize
    });

    return (
        <ReactCrop crop={crop} aspect={1} onChange={c => setCrop(c)} onComplete={c => setCompletedCrop(c)} >
            <img src={src} style={{ ...imgDislaySize }} alt=""/>
        </ReactCrop>
    )
});

function UploadImageCrop({ setUrl, setPercent, saveSize, selectSize, className, accept = "image/*" }) {

    const { show, visible, modalRef } = useModalFade({ scrollHide: false });
    const [completedCrop, setCompletedCrop] = useState({});
    const [imgDislaySize, setImgDislaySize] = useState({});
    const panelSize = {
        width: 600,
        height: 600
    }

    const canvas = document.createElement('canvas');
    canvas.width = saveSize.width;
    canvas.height = saveSize.width;
    const ctx = canvas.getContext('2d');

    // useEffect(() => {
    //     const { x, y, width, height } = completedCrop;
    //     const img = new Image();
    //     img.onload = () => {
    //         ctx.drawImage(img, x / imgDislaySize.scale, y / imgDislaySize.scale, width / imgDislaySize.scale, height / imgDislaySize.scale, 0, 0, sysSize.width, sysSize.height);
    //         const dataUrl = canvas.toDataURL("image/jpeg", 1.0);
    //     }
    //     img.src = original.src;
    // }, [completedCrop]);
    const [original, setOrininal] = useState(null);
    const onSelectFileChange = useCallback((e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                const src = reader.result?.toString() || '';
                const img = new Image();
                img.onload = () => {
                    const { width, height } = img;
                    setOrininal({ width, height, src });
                    setImgDislaySize(getLimitSize(width, height, panelSize.width, panelSize.height));
                    modalRef.current.show();
                }
                img.src = src;
            })
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = '';
        }
    }, [modalRef,panelSize.width, panelSize.height]);

    function onSubmit() {
        var { x, y, width, height } = completedCrop;
        if (!width) {
            // toast.show("请用鼠标框选一块区域");
            x = (imgDislaySize.width - saveSize.width) / 2;
            y = (imgDislaySize.height - saveSize.height) / 2;
            width = saveSize.width;
            height = saveSize.height;
        };

        const img = new Image();
        img.onload = async function () {
            ctx.drawImage(img, x / imgDislaySize.scale, y / imgDislaySize.scale, width / imgDislaySize.scale, height / imgDislaySize.scale, 0, 0, saveSize.width, saveSize.height);
            const b64Image = canvas.toDataURL("image/jpeg", 1.0);
            // 实际应该是上传完成返回的url
            setUrl(b64Image);

            var u8Image = b64ToUint8Array(b64Image);
            uploadFileAPI(new Blob([u8Image], { type: "image/jpg" }), rs => {
                if (rs.status/1 === 1) {
                    const { loaded, total } = rs.ProgressEvent;
                    const progress = Math.round((loaded / total) * 100);
                    // console.log(`Upload Progress: ${progress}%`);
                    setPercent(progress);
                }else if(rs.status/1===2){
                    setUrl(rs.data.message.imageurl)
                } else {
                    toast.show(rs.error);
                }
            })
            // uploadFileAPI(new Blob([u8Image], { type: "image/jpg" }))

            modalRef.current.hide();
        }
        img.src = original.src;

    }
    return (
        <>
            <input className={className} accept={accept} title="选择图片" type="file" name="file" onChange={onSelectFileChange} />
            {createPortal(
                show ?
                    <div className={classNames("crop-image-panel", visible && "crop-image-panel-v")}>
                        {visible && <CropImage src={original.src} imgDislaySize={imgDislaySize} selectSize={selectSize} setCompletedCrop={setCompletedCrop} />}
                        <ButtonGradientPr style={{ width: 150, marginTop: 12 }} onClick={onSubmit}>确定</ButtonGradientPr>
                    </div>
                    : null
                , document.body)}
        </>

    )
};

export {
    UploadImageCrop
} 