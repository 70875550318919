import {Link} from 'react-router-dom';
import classNames from 'classnames';
import user from '@/utils/user';
import React, { useState, useRef } from 'react';

import './index.scss';
import { login } from '../modals';


const Header = ({cur}) => {
    const [showLargeImage, setShowLargeImage] = useState(false);
    const timerRef = useRef(null); // 使用 useRef 存储定时器引用

    return (
        <header className="header">
            <div className="header-content">
                <div className="logo">
                    <Link to="/"></Link>
                </div>
                <div className="menu">
                    <Link className={classNames(cur === 'home' && 'cur')} to="/">
                        首页
                    </Link>
                    <Link className={classNames(cur === 'findMusic' && 'cur')} to="/find">
                        i听音乐
                    </Link>
                    <Link className={classNames(cur === 'myMusic' && 'cur')} to="/my">
                        我的音乐
                    </Link>
                    <Link className={classNames(cur === 'join' && 'cur')} to="/join">
                        加入我们
                    </Link>
                    <Link className={classNames(cur === 'about' && 'cur')} to="/about">
                        关于
                    </Link>
                </div>
                <div className="user-status">
                    {user.isLogin() ? (
                        <>
                            <Link className="user-info" to="/account">
                                <img src={user.avatar} alt="" />
                                <span>{user.nikeName}</span>
                            </Link>
                            <div className="logout-btn" onClick={()=>{
                                user.logout();
                            }}>登出</div>
                        </>
                    ) : (
                        <div className="login-btn" onClick={()=>{ login.show() }}>登录</div>
                    )}
                </div>
                <div className='download-qr' 
                     onMouseEnter={() => {
                         clearTimeout(timerRef.current); // 清除之前的定时器
                         setShowLargeImage(true);
                     }} 
                     onMouseLeave={() => {
                         timerRef.current = setTimeout(() => setShowLargeImage(false), 1000);
                     }}>
                    <div className='show-font'>
                        <img className='small-qr' src={require('@/assets/images/Android_robot.png')} alt="二维码" />
                        <Link to="https://caichong-source.oss-cn-zhangjiakou.aliyuncs.com/android/application-7c961ca5-0700-45a4-9a1d-44aa1634d249.apk.1.apk.1.1"><span>Android端</span></Link>
                    </div>
                    
                    {showLargeImage && (
                        <img className='big-qr' src={require('@/assets/images/QR_code.png')} alt="二维码" />
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
