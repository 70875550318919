
import {Login, login} from './login';
import {Alert, alert} from './alert';
import { Confirm, confirm } from './confirm';
import { Toast, toast } from './toast';
import { Payment, payment } from './payment';

// import { SharePopupNew,sharePopupNew } from './sharePopupNew';

export default function Modals (){

    return (
        <>
        <Login />
        <Alert />
        <Toast />
        <Confirm />
        <Payment />
        {/* <SharePopupNew /> */}
        </>
    )
}

export {
    login,
    alert,
    toast,
    confirm,
    payment,
    // sharePopupNew,
}