import axios from 'axios';
import user from './user';

/**
 * 根域名
 * 超时时间
 * 请求拦截器 / 响应拦截器
 * http://192.168.200.147:8005
 */

 let baseURL = process.env.NODE_ENV === 'development' ? 'http://192.168.200.147:8005' : 'https://registapi.caichong.net';
 baseURL = 'https://registapi.caichong.net';
// baseURL = 'https://appapi.caichong.net';

const request = axios.create({
    baseURL: baseURL,
    timeout: 10000
});

// 请求拦截器，
request.interceptors.request.use(
    (config) => {
        // console.log(config);
        const token = user.token || localStorage.getItem('_TOKEN');
        if (token) {
            // config.headers.Authorization = `token ${token}`;
            config.headers['token'] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截
request.interceptors.response.use(
    (response) => {
        // 2xx 范围内的状态码都会触发该函数
        return response.data;
    },
    (error) => {
        // 超出2xx 范围的状态码都会触发该函数

        try {
            // 监控401 token失效
            if (error.response.status === 503) {
                // removeToken();
                // router.navigate('/login');
            }
            return Promise.reject(error);
        } catch (e) {
            console.log('request error:', e);
        }

    }
);

export { request };
