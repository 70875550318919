import { useEffect, useState } from "react";
import useModalFade from "@/hooks/useModalFade";
import Portal from "../portal";
import classNames from "classnames";

import "./index.scss";

let confirm = {};
const Confirm = () => {

    const { show, visible, modalRef } = useModalFade();
    const [info, setInfo] = useState({ title: "", content: '' });

    useEffect(() => {
        confirm = {
            show: (info) => {
                let _info = {};
                if (typeof (info) == 'string') {
                    _info = {
                        title: '才虫提醒您',
                        content: info
                    }
                } else {
                    _info = info;
                }
                setInfo(_info);
                modalRef.current.show();
                return new Promise(resolve => {
                    confirm.resolve = function (rs) {
                        confirm.hide();
                        resolve(rs);
                    }
                })
            },
            hide: modalRef.current.hide
        }
        return () => {
            confirm?.hide();
        }
    }, []);
    return (
        show &&
        <Portal>
            <div className={classNames("confirm-panel-bg", visible && "confirm-panel-bg-v")}>
                <div className="confirm-panel">
                    <div className="cp-header">
                        {info.title}
                    </div>
                    <div className="cp-content">
                        <span>{info.content}</span>
                    </div>
                    <div className="cp-button">
                        <span onClick={() => confirm.resolve(false)}>取消</span>
                        <span className="cp-sure" onClick={() => confirm.resolve(true)}>确定</span>
                    </div>
                </div>
            </div>
        </Portal>
    )
}

export {
    Confirm,
    confirm
}