import { memo, useEffect, useImperativeHandle, useRef, useState } from "react"
import ButtonGradientPr from "../buttonGradientPr";

import Portal from "../portal";
import classNames from "classnames";
import { getAuthMobileCodeAPI, submitMobileCodeAPI, submitUserInfoAPI ,userVerifyPassword,userRegister} from "@/apis/user";
import { toast } from "../modals";
import { getCharLength } from "@/utils";
import { pageScrollHide } from "@/utils/base";

// import Cookies from 'js-cookie';
import { UploadImageCrop } from "../uploadImageCrop";
import user from "@/utils/user";
import {useRegisterUserInfoStore} from '@/store';

import "./index.scss";

// const setCookie = (key, value) => {
//     Cookies.set(key, value);
// }

let login = {};

const LoginDom = ({setShowLogin,setShowRegister}) => {

    const [mobile, setMobile] = useState('');
    const [smsCode, setSmsCode] = useState('');
    const [password, setPassword] = useState('');
    const [invitationCode, setInvitationCode] = useState('');
    const [agree, setAgree] = useState(false);
    const [errList, setErrList] = useState([]);

    const [hasInvitation, setHasInvitation] = useState(false);
    const [smsSecond, setSmsSecond] = useState(0);
    const [smsDelay, setSmsDelay] = useState(false);
    const [isNew, setIsNew] = useState(true);
    useEffect(() => {
        var timer;
        if (smsDelay) {
            setSmsSecond(60);
            timer = setInterval(() => {
                setSmsSecond(seconds => {
                    if (seconds < 2) {
                        setSmsDelay(false);
                    } else {
                        return seconds - 1;
                    }
                })
            }, 1000);
        } else {
            clearInterval(timer);
        }
        return () => {
            clearInterval(timer);
        }
    }, [smsDelay])

    const getSmsCode = async () => {
        if (smsDelay) return;
        if (!/^\d{11}$/.test(mobile)) {
            setErrList(['请输入正确的手机号'])
        } else {
            setSmsDelay(true);
            setErrList([]);

            // getAuthMobileCodeAPI({
            //     mobile,
            //     invitecode: invitationCode
            // }).then(rs => {
            //     if (rs.retcode == '100') {
            //         toast.show(rs.message.msg);
            //         setIsNew(rs.message.isNew == 1)
            //     } else if (rs.retcode == '206') {
            //         setSmsDelay(false);
            //         toast.show(rs.errmsg);
            //         setHasInvitation(true);
            //     } else {
            //         setSmsDelay(false);
            //         toast.show(rs.errmsg);
            //         setErrList([rs.errmsg]);

            //     }
            // })

            try {
                const rs = await getAuthMobileCodeAPI({
                    mobile
                });
                if (rs.retcode === 100) {
                    toast.show(rs.message.msg, 3000);
                    setIsNew(rs.message.isNew === 1)
                } else if (rs.retcode === 206) {
                    setSmsDelay(false);
                    toast.show(rs.errmsg);
                    setHasInvitation(true);
                } else {
                    setSmsDelay(false);
                    toast.show(rs.errmsg);
                    setErrList([rs.errmsg]);

                }
            } catch (err) {
                console.log(err);
            }

        }
    };

    const validate = () => {
        setErrList([]);
        const _err = [];
        if (mobile === '' || !/^\d{11}$/.test(mobile)) _err.push('请输入正确的手机号');
        if (password === '') _err.push('请输入密码');
        if (!validatePassword(password)) _err.push('请检查密码格式，格式要求大小写字母+数字+特殊字符');
        if (!agree) _err.push('请阅读并同意《用户协议》和《隐私政策》');
        setErrList(_err);
        return _err.length < 1
    }
    const onSubmit = async (_isNew) => {

        if (validate()) {
            try {
                const rs = await userVerifyPassword({
                    mobile,
                    password
                    // code: smsCode
                });

                if (rs.retcode === 100) {
                    const { isNew: _isNew, token } = rs.message;

                    if (_isNew) {
                        localStorage.setItem("_TOKEN", rs.message.token);
                        login.showUserInfoSet();
                    } else {
                        localStorage.removeItem("_TOKEN");
                        // toast.show('登录成功');
                        // //临时前端自己种cookie
                        // setCookie('token', token);
                        // setCookie('uinfo', '11111|用户名|https://vi1.6rooms.com/live/2023/09/04/20/1003v1693829702496079781.jpg');
                        // setCookie('vipinfo', '1|3232');
                        // 拉取用户详细信息, 网页端直接读cookie
                        // getMyInfoAPI().then()
                        // localStorage.removeItem('_TOKEN');
                        user.token = token;
                        user.updateInfo().then(() => {
                            // setTimeout(() => {
                            //     window.location.reload();
                            // }, 1000);
                            window.location.reload();
                        });
                    }
                } else {
                    setErrList([rs.errmsg])
                }
            } catch (err) {
                console.log(err);
            }
        }
    }

    const changeRegister = () => {
        setShowLogin(false);
        setShowRegister(true);
    }

    const validatePassword =(password)=> {
        const hasDigit = /[0-9]/.test(password);
        const hasLower = /[a-z]/.test(password);
        const hasUpper = /[A-Z]/.test(password);
        const hasSpecial = /[~!@#$%^&*?_\\\-\.]/.test(password); // 修正了这里的正则表达式
        
        // 计算满足条件的类型数量
        const typesCount = [hasDigit, hasLower, hasUpper, hasSpecial].filter(Boolean).length;
    
        // 检查是否满足条件
        return typesCount >= 3 && password.length >= 6 && password.length <= 18;
    }

    return (
        <div className="login-panel">
            <div className="login-panel-close" onClick={login.hide}></div>
            <div className="lp-header"><span></span></div>
            <div className="login-panel-container">
                <div className="lb-form">
                    <div className="mobile-id"><input type="tel" pattern="\d{11}" required onChange={(e) => setMobile(e.target.value)} value={mobile} name="mobile_id" placeholder="请输入手机号" /></div>
                    <div className={classNames("invitation-code", hasInvitation && "invitation-code-v")}>
                        <input type="texst" value={invitationCode} pattern="[\w]{5,}" onChange={(e) => setInvitationCode(e.target.value)} name="invitation_code" placeholder="请输入邀请码" />
                    </div>
                    <div className="sms-code">
                        <input type="password" value={password} required onChange={(e) => setPassword(e.target.value)} name="sms_code" placeholder="请输入密码" />
                    </div>
                    {/* <div className="sms-code">
                        <input type="text" value={smsCode} pattern="[\w\d]{4}" required onChange={(e) => setSmsCode(e.target.value)} name="sms_code" placeholder="请输入验证码" />
                        <span className={classNames(smsDelay && "disabled")} onClick={getSmsCode}>
                            {smsDelay ? `${smsSecond}s` : '获取验证码'}
                        </span>
                    </div> */}
                    <div style={{ height: errList.length * 22 }} className={classNames("err-info", errList.length > 0 && "err-info-v")}>
                        {errList.map((item, i) => <span key={i}>{item}</span>)}
                    </div>
                    <div className="agreement">
                        <input type="checkbox" onChange={(inp) => {
                            setAgree(inp.target.checked);
                        }} name="agreement" id="agre__input" />
                        <label htmlFor="agre__input">请阅读并同意</label><a href="/about/policy/cc1" target="_blank">《用户协议》</a>和<a href="/about/policy/cc2" target="_blank">《隐私政策》</a>
                    </div>
                </div>
                <div className="handler">
                    <ButtonGradientPr style={{ height: 48, width: "80%" }} onClick={() => onSubmit(isNew)}>
                        登录
                    </ButtonGradientPr>
                    <div onClick={changeRegister} className="register">
                        注册账号→
                    </div>
                </div>
            </div>
        </div>
    )
};

const UserInfoDom = ({setShowRegister,setShowUserInfo,setSuccessRegister}) => {
    // 上一个页面的注册的信息
    const {data,setData} = useRegisterUserInfoStore();
    const [avatarUrl, setAvatarUrl] = useState('');
    const [imgUploadPercent, setImgUploadPercent] = useState(0);
    const [name, setName] = useState('');
    const [count, setCount] = useState(0);
    const [err, setErr] = useState('');

    useEffect(() => {
        if(Object.keys(data).length > 0){
            setAvatarUrl(data.headUrl);
            setName(data.nickname);
        }
    }, [])

    // const setUrl = (url) => {
    //     setAvatarUrl(url);
    // }
    const onInputName = (inp) => {
        const val = inp.target.value.replace(/\s/g, '');
        setName(val);
        setCount(Math.floor(getCharLength(val) / 2));
    }
    const onSubmit = async () => {
        setErr('');
        if (name === '') {
            setErr('昵称不能为空');
        } else if (!/^[\u4E00-\u9FA5\w-]+$/.test(name)) {
            setErr('仅支持中英文、数字、下划线、减号');
        } else if (Math.floor(getCharLength(name) / 2) > 16) {
            setErr('昵称最多16个字符');
        } else {
            try {
                setData({
                    ...data,
                    headUrl: avatarUrl,
                    nickname: name
                })
                const rs = await userRegister({
                    mobile: data.mobile,
                    code: data.smsCode,
                    password: data.password,
                    headUrl: avatarUrl,
                    nickname: name
                });

                if (rs.retcode === 100) {
                    localStorage.removeItem("_TOKEN");
                    // toast.show('登录成功');
                    // const {token, userinfo } = rs.message;
                    //临时前端自己种cookie
                    // setCookie('token', tempToken);
                    // setCookie('uinfo', '11111|用户名|https://vi1.6rooms.com/live/2023/09/04/20/1003v1693829702496079781.jpg');
                    // setCookie('vipinfo', '1|3232');
                    // 拉取用户详细信息, 网页端直接读cookie
                    // getMyInfoAPI().then()
                    setData({})
                    setSuccessRegister(true);
                    setShowUserInfo(false);
                    const { token } = rs.message;
                    user.token = token;
                    user.updateInfo().then(rs => {
                        // setTimeout(() => {
                        window.location.reload();
                        // }, 1000);
                    });

                } else {
                    toast.show(rs.errmsg);
                    setErr(rs.erromsg);
                    setShowRegister(true);
                    setShowUserInfo(false);
                }
            } catch (err) {
                console.log(err);
                setErr('网络异常');
            }
        }
    };


    return (
        <div className="login-sec">
            <div className="ls-header">
                <div className="avatar">
                    <span className={classNames("img-upload-percent", (imgUploadPercent > 0 && imgUploadPercent < 100) && "img-upload-percent-v")}>{imgUploadPercent}%</span>
                    <UploadImageCrop
                        className="input-file"
                        accept="image/*"
                        saveSize={{ width: 300, height: 300 }}
                        selectSize={{ width: 120, height: 120 }}
                        setUrl={setAvatarUrl}
                        setPercent={setImgUploadPercent}
                    />
                    {!!avatarUrl ? <img src={avatarUrl} /> : <span className="img-btn"></span>}
                </div>
            </div>
            <div className="login-sec-">
                <div className="ls-form">
                    <div className="user-name">
                        <input type="text" pattern="[\u4E00-\u9FA5\w\-]{3,}" required onChange={onInputName} value={name} placeholder="请输用户名" />
                        <span>{count}/16</span>
                    </div>
                    <div className={classNames("err-info", err !== '' && "err-info-v")}>{err}</div>
                </div>
                <div className="handler">
                    <ButtonGradientPr styles={{ height: 48, width: 120 }} onClick={onSubmit}>确定</ButtonGradientPr>
                </div>
            </div>
        </div>
    )
};

const RegisterDom=({ setShowLogin,setShowRegister,setShowUserInfo})=>{


    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [agree, setAgree] = useState(false);
    const {data,setData} = useRegisterUserInfoStore();
    const [smsCode, setSmsCode] = useState('');
    const [smsDelay, setSmsDelay] = useState(false);
    const [smsSecond, setSmsSecond] = useState(0);
    const [errList, setErrList] = useState([]);

    const [hasInvitation, setHasInvitation] = useState(false);
    const [isNew, setIsNew] = useState(true);

    useEffect(() => {
        if(Object.keys(data).length > 0){
            setMobile(data.mobile);
            // setPassword(data.password);
            setSmsCode(data.smsCode);
        }
    }, [])

    useEffect(() => {
        var timer;
        if (smsDelay) {
            setSmsSecond(60);
            timer = setInterval(() => {
                setSmsSecond(seconds => {
                    if (seconds < 2) {
                        setSmsDelay(false);
                    } else {
                        return seconds - 1;
                    }
                })
            }, 1000);
        } else {
            clearInterval(timer);
        }
        return () => {
            clearInterval(timer);
        }
    }, [smsDelay])
    const handleBack=()=>{
        setShowLogin(true);
        setShowRegister(false);
    }
    const getSmsCode = async () => {
        if (smsDelay) return;
        if (!/^\d{11}$/.test(mobile)) {
            setErrList(['请输入正确的手机号'])
        } else {
            setSmsDelay(true);
            setErrList([]);
            try {
                const rs = await getAuthMobileCodeAPI({
                    mobile
                });
                if (rs.retcode === 100) {
                    toast.show(rs.message.msg, 3000);
                    setIsNew(rs.message.isNew === 1)
                } else if (rs.retcode === 206) {
                    setSmsDelay(false);
                    toast.show(rs.errmsg);
                    setHasInvitation(true);
                } else {
                    setSmsDelay(false);
                    toast.show(rs.errmsg);
                    setErrList([rs.errmsg]);

                }
            } catch (err) {
                console.log(err);
            }

        }
    };

    const validate = () => {
        setErrList([]);
        const _err = [];
        // 检查手机号  
        if (mobile === '' || !/^\d{11}$/.test(mobile)) {  
            _err.push('请输入正确的手机号'); 
            setErrList(_err); 
            return false; // 如果手机号不正确，直接返回错误列表  
        }  
        
        // 检查验证码  
        if (smsCode === '') {  
            _err.push('请输入验证码');  
            setErrList(_err);
            return false; // 如果验证码为空，直接返回错误列表  
        }  
        
        // 检查密码  
        if (password === '') {  
            _err.push('请输入密码');  
            setErrList(_err);
            return false; // 如果密码为空，直接返回错误列表  
        }  

        // 检查密码长度和复杂度
        if (!validatePassword(password)){
            _err.push('请检查密码格式，格式要求大小写字母+数字+特殊字符');  
            setErrList(_err);
            return false;
        }
        
        // 检查是否同意用户协议和隐私政策  
        if (!agree) {  
            _err.push('请阅读并同意《用户协议》和《隐私政策》');  
            setErrList(_err);
            return false; // 如果未同意，直接返回错误列表  
        }  
        setErrList([]);
        return true
    }

    const handleNext=()=>{
        console.log(validate())
        if(validate()){
            setData({
                ...data,
                mobile,
                password,
                smsCode
            });
            setShowRegister(false);
            setShowUserInfo(true)
        };

    }

    const validatePassword =(password)=> {
        const hasDigit = /[0-9]/.test(password);
        const hasLower = /[a-z]/.test(password);
        const hasUpper = /[A-Z]/.test(password);
        const hasSpecial = /[~!@#$%^&*?_\\\-\.]/.test(password); // 修正了这里的正则表达式
        
        // 计算满足条件的类型数量
        const typesCount = [hasDigit, hasLower, hasUpper, hasSpecial].filter(Boolean).length;
    
        // 检查是否满足条件
        return typesCount >= 3 && password.length >= 6 && password.length <= 18;
    }

    return(
        <div className="login-panel">
            <div className="back-login" onClick={handleBack}>
               {"<"} 登录
            </div>
            <div className="login-panel-close" onClick={login.hide}>
            </div>
            <div className="lp-header"><span></span></div>
            <div className="login-panel-container">
                <div className="lb-form">
                    <div className="mobile-id">
                        <input type="tel" pattern="\d{11}" required onChange={(e) => setMobile(e.target.value)} value={mobile} name="mobile_id" placeholder="请输入手机号" />
                    </div>
                    <div className="sms-code">
                        <input type="text" value={smsCode} pattern="[\w\d]{4}" required onChange={(e) => setSmsCode(e.target.value)} name="sms_code" placeholder="请输入验证码" />
                        <span className={classNames(smsDelay && "disabled")} onClick={getSmsCode}>
                            {smsDelay ? `${smsSecond}s` : '获取验证码'}
                        </span>
                    </div>
                    <div className="sms-code">
                        <input type="password" value={password} required onChange={(e) => setPassword(e.target.value)} name="sms_code" placeholder="请输入大小写字母+数字+特殊字符的密码" />
                    </div>
                    <div style={{ height: errList.length * 22 }} className={classNames("err-info", errList.length > 0 && "err-info-v")}>
                        {errList.map((item, i) => <span key={i}>{item}</span>)}
                    </div>
                    <div className="agreement">
                        <input type="checkbox" onChange={(inp) => {
                            setAgree(inp.target.checked);
                        }} name="agreement" id="agre__input" />
                        <label htmlFor="agre__input">请阅读并同意</label><a href="/about/policy/cc1" target="_blank">《用户协议》</a>和<a href="/about/policy/cc2" target="_blank">《隐私政策》</a>
                    </div>
                </div>
                <div className="handler">
                        <ButtonGradientPr style={{ height: 48, width: "80%" }} onClick={() => {handleNext()}}>
                            下一步
                        </ButtonGradientPr>
                </div>
            </div>
        </div>
    )
}

const RegisterSuccess = () => {

    return (
        <div className="login-panel" style={{width:'420px',height:'300px'}}>
             <div className="login-panel-close" onClick={login.hide}>
             </div>
             <div className="success-bg">
             </div>
            <div className="success-font">注册成功</div>
            <ButtonGradientPr style={{ height: "36px", width: "200px", margin: '0 auto'}} onClick={() => console.log(1)}>
                OK
            </ButtonGradientPr>
        </div>
    )

}

const Login = memo((props) => {
    // let { visible: visible, setVisible} = props;

    const [visible, setVisible] = useState(false);
    const [show, setShow] = useState(false);
    const [showLogin, setShowLogin] = useState(true);

    const [showRegister, setShowRegister] = useState(false);
    const [successRegister, setSuccessRegister] = useState(false);
    const [showUserInfo, setShowUserInfo] = useState(false);

    // const [tempToken, setTempToken] = useState('');

    const loginRef = useRef(null);

    useImperativeHandle(loginRef, () => {
        return {
            show() {
                setTimeout(() => {
                    setVisible(true)
                }, 0)
                pageScrollHide(1);
                return setShow(true);
            },
            hide() {
                setTimeout(() => {
                    setShow(false);
                    
                    setShowLogin(true);
                    setShowRegister(false);
                    setSuccessRegister(false);
                }, 300)
                pageScrollHide(0);
                return setVisible(false);
            },
            showUserInfoSet() {
                // setTempToken();
                return setShowLogin(false);
            },
            showRegister(){
                setShowLogin(false);
                return setShowRegister(true);
            }
        }
    });
    useEffect(() => {
        login = loginRef.current;
        return () => {
            login?.hide();
        }
    }, [])

    return (
        show &&
        <Portal>
            <div className={classNames("login-panel-wrapper", visible && "login-panel-wrapper-v")}>
                {/* :登录 */}
                {showLogin && <LoginDom setShowLogin={setShowLogin} setShowRegister={setShowRegister}/> }
                {/* 注册页面 */}
                {showRegister && <RegisterDom setShowLogin={setShowLogin} setShowRegister={setShowRegister}  setShowUserInfo={setShowUserInfo}/>}
                {/* 显示用户信息 */}
                {showUserInfo && <UserInfoDom setSuccessRegister={setSuccessRegister} setShowRegister={setShowRegister} setShowUserInfo={setShowUserInfo}/>}
                {/* 注册成功 */}
                {successRegister && <RegisterSuccess /> }
            </div>
        </Portal>
    )
});

export {
    Login,
    login
};