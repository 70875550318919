import Header from '@/components/header';
import Footer from '@/components/footer';

import './index.scss';
import { useEffect,useState } from 'react';

const Main = () => {
    const [height, setHeight] = useState(0)
    useEffect(() => {
        document.title="才虫.AI";
        
        setHeight(document.body.clientHeight - (72))
    }, []);



    return (
        <div className="main-wrapper">
            <Header cur={'home'} />
            <div className="home-content" style={{height: height}}>
                {/* <div className="title">
                    <h2>" 感受每一个音符的力量 "</h2>
                    <h6>CaiChong带您探索独特的音乐和创新艺术</h6>
                </div> */}
            </div>
            <div className='home-info' style={{minHeight: height}}>
                <div className="home-info-item">
                    <div className='home-info-item-details home-info-item-bg1'></div>
                    <div className='home-info-item-details home-info-item-bg2'></div>
                    <div className='home-info-item-details home-info-item-bg3'></div>
                    <div className='home-info-item-details home-info-item-bg4'></div>
                    <div className='home-info-item-details home-info-item-bg5'></div>
                    <div className='home-info-item-details home-info-item-bg6'></div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Main;
