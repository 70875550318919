import {memo, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import classNames from 'classnames';
import Portal from '../portal';
import wxpay_icon from '../../assets/images/wxpay_icon.png';
import pay_success from '../../assets/images/pay_success.png';
import ButtonGradientPr from '../buttonGradientPr';

import './index.scss';
import {createPreOrderAPI, downloadAPI, getPayResultAPI} from '@/apis/music';
import { toast } from '../modals';

let payment = {};

const Payment = memo((props) => {
    const paymentRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const [show, setShow] = useState(false);
    const [songid, setSongid] = useState('');
    const [payTitle, setPayTitle] = useState('');
    const [codedata, setCodedata] = useState();
    const [successPay, setSuccessPay] = useState(false);
    const [downData, setDownData] = useState({data:'#'});
    const payTimer = useRef(null);

    const songDownload= useCallback( async (songid)=>{
        const rs = await downloadAPI({songid: songid});
        if (rs.retcode === 100) {
            const a = document.createElement('a');
            a.download = rs.message.songname;
            a.href = 'data:audio/mpeg;base64,' + rs.message.data;
            a.click();
            setDownData(rs.message);
        }else{
            toast.show(rs.errmsg)
        }
    },[]);

    useEffect(() => {
        payment = paymentRef.current;
        return () => {
            payment?.hide();
        };
    }, []);
    useEffect(() => {
        if (!songid || !visible) return;

        const getPayInfo = async () => {
            try {
                const rs = await createPreOrderAPI({
                    paytype: 2,
                    songid: songid
                });
                if (rs.retcode === 100) {
                    setPayTitle(rs.message.msg);
                    setCodedata(rs.message.codedata);
                    payTimer.current = setInterval(async () => {
                        try {
                            const res = await getPayResultAPI({
                                orderid: rs.message.orderid
                            });
                            if (res.message?.status === 1) {
                                setSuccessPay(res.message.status === 1);
                                clearInterval(payTimer.current);
                                songDownload(songid);
                            }
                        } catch (e) {}
                    }, 2000);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getPayInfo();

        return () => {
            setSuccessPay(false);
            clearInterval(payTimer.current);
        };
    }, [songid, visible,songDownload]);

    useImperativeHandle(paymentRef, () => {
        return {
            show(songid) {
                setSongid(songid);
                setTimeout(() => {
                    setVisible(true);
                }, 0);
                return setShow(true);
            },
            hide() {
                setTimeout(() => {
                    setShow(false);
                }, 300);
                clearInterval(payTimer.current);
                return setVisible(false);
            }
        };
    });
    const payInfoCard = (
        <div
            className={classNames(
                'pay-card-wrapper',
                visible && 'pay-card-wrapper-v'
            )}>
            <div className="pay-card">
                <div className="pay-head">
                    <span>付费购买</span>
                    <div
                        className="pay-card-close"
                        onClick={payment.hide}></div>
                </div>
                <div className="pay-body">
                    <img src={`data:image/png;base64,${codedata}`} alt="" />
                    <div className="pay-info">
                        <span>{payTitle}</span>
                        <span>
                            实付：¥<span>{'3.00'}</span>
                        </span>
                        {/* src/assets/images/wxpay_icon.png */}
                        <span>
                            <i>请使用</i>
                            <img src={wxpay_icon} alt="" />
                            <i>微信扫码支付</i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
    const successCard = (
        <div
            className={classNames(
                'pay-card-wrapper',
                visible && 'pay-card-wrapper-v'
            )}>
            <div className="pay-success">
                <div className="pay-head">
                    <img src={pay_success} alt="" />
                    <div
                        className="pay-card-close"
                        onClick={payment.hide}></div>
                </div>
                <div className="pay-body">
                    <span>付费成功！</span>
                    <span>如果未自动下载，<a download={downData?.songname} href={downData?.data}>请点击这里下载</a></span>
                    <ButtonGradientPr
                        style={{height: 36, width: 200}}
                        onClick={payment.hide}>
                        OK
                    </ButtonGradientPr>
                </div>
            </div>
        </div>
    );
    return (
        show && <Portal>{successPay ? successCard : payInfoCard}</Portal>
    );
});

export {Payment, payment};
