import { createBrowserRouter } from "react-router-dom";
import Main from "@/pages/main";
// import MobileTemp from "@/pages/mobileTemp";
import { Suspense, lazy } from 'react';
const About = lazy(() => import('@/pages/about'));
const Policy = lazy(() => import('@/pages/policy'));
const Page404 = lazy(() => import('@/pages/page404'));
const Music = lazy(() => import('@/pages/music'));
const Find = lazy(() => import('@/pages/find'));

const Account = lazy(() => import('@/pages/my'));
const My = lazy(() => import('@/pages/my/my'));
const Category = lazy(() => import('@/pages/find/category'));
const Join = lazy(() => import('@/pages/join'));
const Details = lazy(() => import('@/pages/details'));

// if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
//     window.location.replace('http://m.caichong.net' + window.location.pathname + window.location.search)
// }

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
        title:"才虫.AI"
    },
    {
        path: "/music",
        element: <Suspense><Music /></Suspense>,
        title:'才虫.AI - 音乐库'
    },
    {
        path: "/find",
        element: <Suspense><Find /></Suspense>,
        title:'才虫.AI - 音乐库'
    },
    {
        path: "/my",
        element: <Suspense><My /></Suspense>,
        title:'才虫.AI - 我的音乐'
    },
    {
        path: "/category/:id",
        element: <Suspense><Category /></Suspense>,
        title:'才虫.AI - 我的音乐'
    },
    {
        path: "/about",
        element: <Suspense><About /></Suspense>,
        title:'才虫.AI - 关于我们'
    },
    {
        path: "/account",
        element: <Suspense><Account /></Suspense>,
        title:'才虫.AI - 账户中心'
    },
    {
        path: "/about/policy/cc1",
        element: <Suspense><Policy cc={"cc1"} /></Suspense>,
        title:'才虫.AI - 用户协议'
    },
    {
        path: "/about/policy/cc2",
        element: <Suspense><Policy cc={"cc2"} /></Suspense>,
        title:'才虫.AI - 隐私协议'
    },
    {
        path: "/join",
        element: <Suspense><Join /></Suspense>,
        title:'才虫.AI - 加入我们'
    },
    {
        path: "/id/:id",
        element: <Suspense><Details /></Suspense>,
        title:'才虫.AI - 音乐详情'
    },
    {
        path: "/*",
        element: <Suspense><Page404 /></Suspense>,
        title:'才虫.AI - 页面未找到'
    }
]);

export default router;