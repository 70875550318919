import { create } from "zustand";

const useAudioPlayerStore = create((set) => ({
    mid: 0,
    setMid: (mid) => set(() => ({ mid: mid })),
    removeMid: () => set({ mid: 0 }),
    playing:false,
    setPlaying:(status)=>set(()=>({playing:status}))
}));

export default useAudioPlayerStore;