import { getMyInfoAPI, logoutAPI } from '@/apis/user';
import { login } from '@/components/modals';
// import Cookies from 'js-cookie';
// Cookies.set('U_INFO', '11111|用户名|https://vi1.6rooms.com/live/2023/09/04/20/1003v1693829702496079781.jpg|tokenasklfjw423@!&')
// Cookies.set('M_INFO', '0|3232')

class User {
    uid
    nikeName
    avatar
    token
    member
    points
    defAvatar='https://caichong-avatar.oss-cn-zhangjiakou.aliyuncs.com/static/def_avatar.png'
    constructor() {
        this.refresh();
    }
    // setCookie('uinfo', '11111|用户名|https://vi1.6rooms.com/live/2023/09/04/20/1003v1693829702496079781.jpg');
    // setCookie('vipinfo', '1|3232');
    setLogin = (_user) => {
        const avatar = _user.headimgurl===''?'https://caichong-avatar.oss-cn-zhangjiakou.aliyuncs.com/static/def_avatar.png':_user.headimgurl;
        localStorage.setItem('TOKEN', _user.token);
        localStorage.setItem('UINFO', `${_user.uid}|${_user.nickname}|${avatar}`);
        // localStorage.setItem('VINFO', `${_user.vinfo}||0`);
        localStorage.setItem('VINFO', '0|0');
        localStorage.setItem('MUSICTOKENNUM', _user.resTokenNum);
        this.refresh();
    }
    updateInfo = ()=>{
        return new Promise(resolve=>{
            const token =  this.token;
            getMyInfoAPI().then(rs => {
                this.setLogin({ token , ...rs.message.userinfo, resTokenNum:rs.message.resTokenNum })
                resolve();
            })
        })
       
    }
    refresh = () => {

        // const token = Cookies.get('token') || '';
        // const uinfo = Cookies.get('uinfo');
        // const vinfo = Cookies.get('vipinfo');
        const token = localStorage.getItem('TOKEN') || '';
        const uinfo = localStorage.getItem('UINFO');
        const vinfo = localStorage.getItem('VINFO');
        const musicTokenNum = localStorage.getItem('MUSICTOKENNUM')||0;
        this.musicTokenNum = musicTokenNum;
        if (uinfo) {
            const [uid, nikeName, avatar] = uinfo.split('|');
            this.uid = uid/1;
            this.nikeName = nikeName;
            this.avatar = avatar;
        }
        if (!!vinfo) {
            const [member, points] = vinfo.split('|');
            this.member = member;
            this.points = points;
        } else {
            this.member = 0;
            this.points = 0;
        }

        this.token = token;
        if (!this.uid && !this.token) {
            // const gid = Cookies.get('ginfo');
            // if(gid){
            //     this.guestID = gid;
            // }else{
            //     //this.getGuestID().then(this.refresh);
            // }
        }
    }
    isLogin = () => {
        return !!this.uid;
    }
    forceLogin = () => {
        return new Promise(resolve => {
            if (this.isLogin()) {
                resolve(true);
            } else {
                login.show();
                resolve(false);
            }
        })
    }
    getGuestID = () => {
        return (new Date()).getTime();
        // return new Promise(resolve => {
        //     resolve(true);
        //     // const rs = this.getGuestID();
        //     // return rs;
        // })
    }
    login() {
        login.show();
    }
    logout() {
        // Cookies.set('uinfo', '');
        // Cookies.set('vipinfo', '');
        // Cookies.set('token', '');
        logoutAPI({})
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('UINFO');
        localStorage.removeItem('VINFO');
        window.location.reload();
    }
};


const user = new User();

export default user;