import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import router from '@/router';
import Modals from './components/modals';
import "./index.scss";
const root = ReactDOM.createRoot(document.getElementById('root'));
document.body.removeChild(document.querySelector('noscript'));
root.render(
    <>
    <RouterProvider router={router} />
    <Modals />
    </>
);

